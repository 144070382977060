import { render, staticRenderFns } from "./talentDialog.vue?vue&type=template&id=6c897053&scoped=true&"
import script from "./talentDialog.vue?vue&type=script&lang=js&"
export * from "./talentDialog.vue?vue&type=script&lang=js&"
import style0 from "./talentDialog.vue?vue&type=style&index=0&id=6c897053&prod&lang=less&scoped=true&"
import style1 from "./talentDialog.vue?vue&type=style&index=1&id=6c897053&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c897053",
  null
  
)

export default component.exports